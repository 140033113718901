import axios from "axios";
import Cookies from "./cookies";
import store from "../store";
import { logout } from "../modules/Auth/slice/AuthSlice";

const BASE_URL = "https://api.sunniva.ai";
// const BASE_URL = "http://192.168.1.180:8000";


export const TEXTILE_ADMIN_API_URL = `${BASE_URL}/api`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Token ")
        ? token
        : "Token " + token;
    // console.log(token,'token')

    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.

    if (response?.data?.token) {
      if (response?.data?.is_authorized) {
        Cookies.set("token", response.data.token);
      }
    }
    return response;
  };

  _responseErr = (error) => {
    //if error comes than handle here
    if (error?.response?.status === 401 || !localStorage.getItem("userId")) {
      Cookies?.clear();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosTextileAdmin = new Axios(TEXTILE_ADMIN_API_URL).axios;

export { axiosTextileAdmin };
