import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "../../../services/cookies";
import { axiosTextileAdmin } from "../../../services/api";
import { GET_IMAGE_COUNT_URL } from "../../../constants/url";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  imageData: null,
  error: null,
};

export const getImageCount = createAsyncThunk(
  `dashboard/getImageCount`,
  async (payload, thunkAPI) => {
    const { callback, startDate, endDate } = payload;
    try {
      const response = await axiosTextileAdmin.get(
        `${GET_IMAGE_COUNT_URL}?start_date=${startDate}&end_date=${endDate}`
      );

      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      toast.error(err.response.data.error);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.non_field_errors?.at(0)
      );
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getImageCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getImageCount.fulfilled, (state, action) => {
      state.loading = false;
      state.imageData = action.payload;
    });
    builder.addCase(getImageCount.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export default dashboardSlice.reducer;
