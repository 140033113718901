import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USER } from "../../../constants/url";
import { axiosTextileAdmin } from "../../../services/api";
import { toast } from "react-toastify";

const initialState = {
  userLoading: false,
  userList: [],
  businessList: [],
  userCount: 0,
  businessCount: 0,
  category: null,
};

export const FetchUsers = createAsyncThunk(
  `/list-user`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosTextileAdmin.get(
        USER + `?page=${payload.page}&search=${payload.search}&sortField=${payload.sortField?payload.sortField:""}&sortOrder=${payload.sortOrder?payload.sortOrder:""}`
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);
export const storeCategoryDetail = createAsyncThunk(
  `/category`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosTextileAdmin.post(`/category`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.non_field_errors?.at(0)
      );
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FetchUsers.pending, (state) => {
      state.userLoading = true;
    });
    builder.addCase(FetchUsers.fulfilled, (state, action) => {
      state.userLoading = false;
      state.userList = action.payload?.[0]?.individual?.results;
      state.userCount = action.payload?.[0]?.individual?.count;
      state.businessList = action.payload?.[1]?.business?.results || [];
      state.businessCount = action.payload?.[1]?.business?.count;
    });
    builder.addCase(FetchUsers.rejected, (state, action) => {
      state.userLoading = false;
    });
    builder.addCase(storeCategoryDetail.fulfilled, (state, action) => {
      state.category = action.payload;
    });
  },
});

export default userSlice.reducer;
