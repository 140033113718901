export const LOGIN = "/login";
export const USER_PATTEN = "/v1";
export const USER = "/list-user";
export const USER_ACTIVITY = "/list-gen";
export const ADD_USER = "/register";
export const MODAL_CONFIGURATION = "/configurations";

// dashboard

export const GET_IMAGE_COUNT_URL = "/image-count";

//history
export const USER_USAGE_URL = "/usage-log";

//add note
export const ADD_NOTE_URL = "/notes";
