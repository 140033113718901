import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import FullIcon from "../assets/images/logo_transparency.png";
import ShortIcon from "../assets/images/logo_transparency.png";
import Cookies from "../services/cookies";
// import { useDispatch } from "react-redux";
// import { logout } from "../modules/Auth/slice/AuthSlice";

const Header = () => {
  const displayLoginUserName = localStorage.getItem("displayLoginUserName");

  const handleLogout = () => {
    Cookies.clear();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo text-white fw-bold" to="/">
          <img src={FullIcon} alt="logo" />
          {/* <span>AI Textile Pattern</span> */}
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={ShortIcon} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown>
              <Dropdown.Toggle className="nav-link align-items-center justify-content-center">
                <div className="nav-profile-img">
                  {/* <img
                    src={require("../assets/images/AiTextile.png")}
                    alt="user"
                  /> */}
                  <i
                    className="fa-solid fa-user"
                    style={{ color: "white" }}
                  ></i>
                  {/* <span className="availability-status online"></span> */}
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 fw-bold" style={{ color: "white" }}>
                    {displayLoginUserName}
                  </p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                {/* <Dropdown.Item
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  <>Activity Log</>
                </Dropdown.Item> */}
                <Dropdown.Item
                  href="!#"
                  onClick={handleLogout}
                  style={{ color: "black" }}
                >
                  <i
                    className="mdi mdi-logout me-2"
                    style={{ color: "black" }}
                  ></i>
                  Signout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() =>
            document
              .querySelector(".sidebar-offcanvas")
              .classList.toggle("active")
          }
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Header;
