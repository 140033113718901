import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN } from "constants/url";
import { axiosTextileAdmin } from "services/api";

export const LoginThunk = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    const { callback, ...data } = payload;
    try {
      const response = await axiosTextileAdmin.post(LOGIN, data);
      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.non_field_errors?.at(0)
      );
    }
  }
);
