import { USER_USAGE_URL } from "../constants/url";

const routesConstants = {
  HOME_PAGE: "dashboard",
  AUTH: "auth",
  LOGIN: "login",
  USER: "user",
  INDIVIDUAL_USER: "individual-user",
  USER_ACTIVITY: "user-activity",
  USER_PATTEN: "user-patten",
  BUSINESS: "business/:id",
  MODAL_CONFIGURATION: "configurations",
  USER_MATRIC: "user-matric",
  USER_USAGE_URL: "usage-log",
};

export default routesConstants;
