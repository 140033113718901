import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MODAL_CONFIGURATION } from "../../../constants/url";
import { axiosTextileAdmin } from "../../../services/api";
import { toast } from "react-toastify";

const initialState = {
  configurationLoading: false,
  configurations: [],
  configurationCount: 0,
  editConfigurationLoading: false,
  editConfigurations: [],
  editConfigurationCount: 0,
  addConfigurationLoading: false,
  addConfigurations: [],
  addConfigurationCount: 0

};

export const FetchCongiguration = createAsyncThunk(
  `/configurations`,
  async (payload, thunkAPI) => {
    // console.log(payload)
    try {
      const response = await axiosTextileAdmin.get(MODAL_CONFIGURATION + `?page=${payload}`
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);
export const EditCongiguration = createAsyncThunk(
  `EditCongiguration/configurations`,
  async (payload, thunkAPI) => {
    const { callback, customPayload, configurationId } = payload
    // console.log(payload, 'payload111')
    try {
      const response = await axiosTextileAdmin.put(MODAL_CONFIGURATION + `/${configurationId}`, customPayload);
      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);            
export const AddCongiguration = createAsyncThunk(
  `AddCongiguration/configurations`,
  async (payload, thunkAPI) => {
    const { callback,customPayload } = payload
    // console.log(payload, 'payload111')
    try {
      const response = await axiosTextileAdmin.post(MODAL_CONFIGURATION,customPayload);
      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const congigurationSlice = createSlice({
  name: "congiguration",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FetchCongiguration.pending, (state) => {
      state.configurationLoading = true;
    });
    builder.addCase(FetchCongiguration.fulfilled, (state, action) => {
      state.configurationLoading = false;
      state.configurations = action.payload.results;
      state.configurationCount = action.payload.count;
    });
    builder.addCase(FetchCongiguration.rejected, (state, action) => {
      state.configurationLoading = false;
    });

    builder.addCase(EditCongiguration.pending, (state) => {
      state.editConfigurationLoading = true;
    });
    builder.addCase(EditCongiguration.fulfilled, (state, action) => {
      state.editConfigurationLoading = false;
      state.editConfigurations = action.payload.results;
      state.editConfigurationCount = action.payload.count;
    });
    builder.addCase(EditCongiguration.rejected, (state, action) => {
      state.editConfigurationLoading = false;
    });

    builder.addCase(AddCongiguration.pending, (state) => {
      state.addConfigurationLoading = true;
    });
    builder.addCase(AddCongiguration.fulfilled, (state, action) => {
      state.addConfigurationLoading = false;
      state.addConfigurations = action.payload.results;
      state.addConfigurationCount = action.payload.count;
    });
    builder.addCase(AddCongiguration.rejected, (state, action) => {
      state.addConfigurationLoading = false;
    });
  },
});

export default congigurationSlice.reducer;
