import {
  DashboardPage,
  LoginPage,
  UserPage,
  UserPatten,
  BusinessDetail,
  ModalConfiguration,
  UserMatric,
  IndividualUserPge,
  ResizeImge,
  UserUsage,
  UserActivity,
} from "./routeImports";
import routesConstants from "./routesConstants";
const routesConfig = {
  private: [
    {
      path: routesConstants.HOME_PAGE,
      Component: DashboardPage,
    },
    {
      path: routesConstants.USER,
      Component: UserPage,
    },
    {
      path: routesConstants.INDIVIDUAL_USER,
      Component: IndividualUserPge,
    },
    {
      path: routesConstants.USER_PATTEN,
      Component: UserPatten,
    },
    {
      path: routesConstants.BUSINESS,
      Component: BusinessDetail,
    },
    {
      path: routesConstants.MODAL_CONFIGURATION,
      Component: ModalConfiguration,
    },
    {
      path: routesConstants.USER_MATRIC,
      Component: UserMatric,
    },
    {
      path: routesConstants.USER_ACTIVITY,
      Component: UserActivity,
    },
    {
      path: "image",
      Component: ResizeImge,
    },
    {
      
      path: routesConstants.USER_USAGE_URL,
      Component: UserUsage,
    },
    
  ],
  public: [
    {
      path: routesConstants.LOGIN,
      Component: LoginPage,
    },
  ],
};

export default routesConfig;
