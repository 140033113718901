import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../modules/Auth/slice/AuthSlice";
import userPattenSlice from "../modules/User Patten/slice/UserPattenSlice";
import userSlice from "../modules/Users/slice/userSlice";
import userActivitySlice from "../modules/UsersActivity/slice/userSlice";
import businessDetailSlice from "../modules/BusinessDetail/slices/BussinessDetailsSlice";
import configurationSlice from "../modules/ModalConfiguration/slice/configurationSlice";
import dashboardSlice from "../modules/Dashboard/slices/dashboardSlice";
// import commonSlice from "./common/commonSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    users: userSlice,
    userActivity: userActivitySlice,
    userPatten: userPattenSlice,
    businessDetail: businessDetailSlice,
    configurationDetail: configurationSlice,
    dashboard: dashboardSlice,
    // commonSlice: commonSlice,
  },
  devTools: true,
});

export default store;
