import lazy from "../utils/lazy";

export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "../modules/Auth")
);

export const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ "../modules/Dashboard")
);

export const UserPage = lazy(() =>
  import(/* webpackChunkName: "UserPage" */ "../modules/Users")
);
export const IndividualUserPge = lazy(() =>
  import(
    /* webpackChunkName: "IndividualUserPge" */ "../modules/Users/individualUserPge"
  )
);

export const UserPatten = lazy(() =>
  import(/* webpackChunkName: "UserPattenPage" */ "../modules/User Patten")
);
export const BusinessDetail = lazy(() =>
  import(/* webpackChunkName: "BussinessDetail" */ "../modules/BusinessDetail")
);
export const ModalConfiguration = lazy(() =>
  import(
    /* webpackChunkName: "BussinessDetail" */ "../modules/ModalConfiguration"
  )
);
export const UserMatric = lazy(() =>
  import(/* webpackChunkName: "BussinessDetail" */ "../modules/UserMatric")
);

export const UserActivity = lazy(() =>
  import(/* webpackChunkName: "UserActivity" */ "../modules/UsersActivity")
);

export const ResizeImge = lazy(() =>
  import(
    /* webpackChunkName: "ResizeImge" */ "../modules/UserMatric/components/ReSizeImage"
  )
);

export const UserUsage = lazy(() =>
  import(
    /* webpackChunkName: "UserUsage" */ "../modules/Users/components/DeatilsPage"
  )
);
