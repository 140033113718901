import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import AddUser from "../modules/BusinessDetail/components/AddUser";
// import AddUserModal from "../modules/BusinessDetail/components/AddUserModal";

const Sidebar = () => {
  const [toggle, setToggle] = useState([]);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  function toggleMenuState(menuState) {
    if (!!toggle && toggle[menuState]) {
      setToggle({ [menuState]: false });
    } else if (!!toggle && Object.keys(toggle).length === 0) {
      setToggle({ [menuState]: true });
    } else {
      Object.keys(toggle).forEach((i) => {
        setToggle({ [i]: false });
      });
      setToggle({ [menuState]: true });
    }
  }
  const hideModal = () => {
    setShowModal(false);
  };

  const isPathActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  return (
    <>
      {/* <AddUserModal show={showModal} handleClose={hideModal} /> */}
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li
            className={
              isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/dashboard"
              style={{ justifyContent: "space-between" }}
            >
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li
            className={isPathActive("/user") ? "nav-item active" : "nav-item"}
          >
            <Link
              className="nav-link"
              to="/user"
              style={{ justifyContent: "space-between" }}
            >
              <span className="menu-title">Lists Of Business</span>
              <i className="fa-solid fa-users  menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              isPathActive("/individual-user") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/individual-user"
              style={{ justifyContent: "space-between" }}
            >
              <span className="menu-title">Lists Of User </span>

              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li>
          {/* <li
            className="nav-item d-flex justify-content-between align-items-center"
            onClick={() => setShowModal(true)}
          >
            <button className="nav-link" type="button">
              <span className="menu-title" style={{ color: "#b66dff" }}>
                Add User
              </span>
            </button>
            <i
              className="fa-sharp fa-solid fa-plus"
              style={{ color: "#b66dff" }}
            ></i>
          </li> */}

          {/* <li
            className={
              isPathActive("/configurations") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/configurations">
              <span className="menu-title">Modal Configuration</span>
              <i className="mdi mdi-poll menu-icon"></i>
            </Link>
          </li> */}

          {/*<li
            className={
              isPathActive("/user-matric") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/user-matric"
              style={{ justifyContent: "space-between" }}
            >
              <span className="menu-title">User Matric</span>
              <i className="fa-solid fa-paperclip menu-icon"></i>
            </Link>
          </li> /*}

          {/* <li
          className={
            isPathActive("/user-patten") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/user-patten">
            <span className="menu-title">Users Patten</span>
            <i className="mdi mdi-poll menu-icon"></i>
          </Link>
        </li> */}

          <li
            className={
              isPathActive("/user-activity") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/user-activity"
              style={{ justifyContent: "space-between" }}
            >
              <span className="menu-title">User Activity</span>
              <i className="fa-solid fa-paperclip menu-icon"></i>
            </Link>
          </li>

          {/* <li
          className={
            isPathActive("/error-pages") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              toggle?.errorPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("errorPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <>Error Pages</>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-security menu-icon"></i>
          </div>
          <Collapse in={toggle?.errorPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/error-pages/error-404")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/error-pages/error-404"
                >
                  404
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/error-pages/error-500")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/error-pages/error-500"
                >
                  500
                </Link>
              </li>
            </ul>
          </Collapse>
        </li> */}

          {/* <li
          className={isPathActive("/tables") ? "nav-item active" : "nav-item"}
        >
          <div
            className={
              toggle.tablesMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("tablesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <>Tables</>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-table-large menu-icon"></i>
          </div>
          <Collapse in={toggle.tablesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/tables/basic-table")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/tables/basic-table"
                >
                  <>Basic Table</>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>  */}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
