import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosTextileAdmin } from "../../../services/api";
import { USER_PATTEN } from "../../../constants/url";
import { toast } from "react-toastify";

const initialState = {
  userPattenLoading: false,
  userPatten: [],
  userPattenCount: 0,
};

export const FetchUserPatten = createAsyncThunk(
  `userPatten/FetchUserPatten`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosTextileAdmin.get(
        USER_PATTEN + `?page=${payload}`
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const userPattenSlice = createSlice({
  name: "userPatten",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FetchUserPatten.pending, (state) => {
      state.userPattenLoading = true;
    });
    builder.addCase(FetchUserPatten.fulfilled, (state, action) => {
      state.userPattenLoading = false;
      state.userPatten = action?.payload?.results || [];
      state.userPattenCount = action?.payload?.count || 0;
    });
    builder.addCase(FetchUserPatten.rejected, (state) => {
      state.userPattenLoading = false;
    });
  },
});

export default userPattenSlice.reducer;
