import { createSlice } from "@reduxjs/toolkit";
import Cookies from "../../../services/cookies";
import { LoginThunk } from "./AuthThunk";

const token = Cookies.get("token");

const initialState = {
  isAuth: !!token,
  user: null,
  loading: false,
  token: !!token ? token : "",
  error: null,
  userId: localStorage.getItem("userId"),
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    },
    setLoginError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.user = action.payload;
      state.userId = action.payload?.user_id;
      state.loading = false;
      state.token = action.payload?.token;
      state.isAuth = action.payload?.is_authorized;
    });
    builder.addCase(LoginThunk.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
});
export const { logout, setLoginError } = authSlice.actions;

export default authSlice.reducer;
