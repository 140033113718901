import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADD_USER, USER, USER_PATTEN } from "../../../constants/url";
import { axiosTextileAdmin } from "../../../services/api";
import { toast } from "react-toastify";

const initialState = {
  bussinessDetailsLoading: false,
  bussinessDetails: [],
  bussinessDetailsCount: 0,
  addUserDetailLoading: false,
  addUserDetail: [],
  addUserDetailCount: 0,
};

export const FetchBussinessDetail = createAsyncThunk(
  `FetchBussinessDetail/list-user`,
  async (payload, thunkAPI) => {
    // console.log(payload)
    try {
      const response = await axiosTextileAdmin.post(
        USER + `/${payload?.id}?page=${payload?.page}&search=${payload?.search}&sortField=${payload.sortField?payload.sortField:""}&sortOrder=${payload.sortOrder?payload.sortOrder:""}`
      );
      // const response = await axiosTextileAdmin.post(USER + `/${payload?.id}?page=${payload?.page}`);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const AddUserDetail = createAsyncThunk(
  `addUserDetail/register`,
  async (payload, thunkAPI) => {
    const { callback, ...data } = payload;
    try {
      const response = await axiosTextileAdmin.put(
        ADD_USER + `${USER_PATTEN}`,
        data
      );
      // cb(response.data)
      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      if (callback) {
        callback(err);
      }
      // toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);
export const AddIndividualUser = createAsyncThunk(
  `addIndividualUser/register`,
  async (payload, thunkAPI) => {
    const { callback, ...data } = payload;
    try {
      const response = await axiosTextileAdmin.post(
        ADD_USER + `${USER_PATTEN}`,
        data
      );
      // cb(response.data)
      if (callback) {
        callback(response);
      }
      return response.data;
    } catch (err) {
      if (callback) {
        callback(err);
      }
      // toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message
      );
    }
  }
);

export const bussinessDetailsSlice = createSlice({
  name: "Bussiness Details",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FetchBussinessDetail.pending, (state) => {
      state.bussinessDetailsLoading = true;
    });
    builder.addCase(FetchBussinessDetail.fulfilled, (state, action) => {
      state.bussinessDetailsLoading = false;
      state.bussinessDetails = action.payload.results;
      state.bussinessDetailsCount = action.payload.count;
    });
    builder.addCase(FetchBussinessDetail.rejected, (state) => {
      state.bussinessDetailsLoading = false;
    });
    builder.addCase(AddUserDetail.pending, (state) => {
      state.addUserDetailLoading = true;
    });
    builder.addCase(AddUserDetail.fulfilled, (state, action) => {
      state.addUserDetailLoading = false;
      state.addUserDetail = action.payload.results;
      state.addUserDetailCount = action.payload.count;
    });
    builder.addCase(AddUserDetail.rejected, (state) => {
      state.addUserDetailLoading = false;
    });
    builder.addCase(AddIndividualUser.pending, (state) => {
      state.addUserDetailLoading = true;
    });
    builder.addCase(AddIndividualUser.fulfilled, (state, action) => {
      state.addUserDetailLoading = false;
      state.addUserDetail = action.payload.results;
      state.addUserDetailCount = action.payload.count;
    });
    builder.addCase(AddIndividualUser.rejected, (state) => {
      state.addUserDetailLoading = false;
    });
  },
});

export default bussinessDetailsSlice.reducer;
